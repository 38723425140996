export default {
    init() {
        const sprigComponent = document.getElementById('sprig-schedule');
        const lg = 1024;

        if (!sprigComponent) return;

        const searchInputField = sprigComponent.querySelector('.search-field') as HTMLInputElement;
        const submitButton = sprigComponent.querySelector('.submit-button') as HTMLInputElement;
        const clearButton = sprigComponent.querySelector('.clear-results') as HTMLInputElement;

        if (!searchInputField && !submitButton && !clearButton) return;

        let h = 0;

        function debounceNew(callback: () => void, wait = 20) {
            clearTimeout(h);
            h = setTimeout(callback, wait);
        }

        const placeholder = searchInputField.dataset.placeholder;
        const placeholderMobile = searchInputField.dataset.placeholderMobile;

        if (window.matchMedia('screen and (min-width: ' + lg + 'px)').matches) {
            if (placeholder) {
                searchInputField.placeholder = placeholder;
            }

            // show submit button if value is set
            searchInputField.addEventListener('keyup', function (event) {
                if (event.keyCode !== 13) {
                    if (searchInputField.value) {
                        submitButton.classList.remove('hidden');
                        clearButton.classList.add('hidden');
                    } else {
                        submitButton.classList.add('hidden');
                    }
                }
            });

            submitButton.addEventListener('click', function () {
                submitButton.classList.add('hidden');
                clearButton.classList.remove('hidden');
            });

            clearButton.addEventListener('click', function () {
                searchInputField.value = '';
                clearButton.classList.add('hidden');
            });

            if (searchInputField.value) {
                clearButton.classList.remove('hidden');
            }
        } else {
            if (placeholderMobile) {
                searchInputField.placeholder = placeholderMobile;
            }
            searchInputField.addEventListener('keyup', function () {
                debounceNew(() => submitButton.click(), 300);
            });
        }

        const scheduleSearch = document.querySelector('.schedule-search');
        const filterDivMobile = document.querySelector('.schedule-filters-mobile');
        const marke = document.querySelector('.marke');
        const sessions = document.querySelector('.sessions');
        const header = document.querySelector('.header-menu');
        const windowWidth = window.innerWidth;

        if (scheduleSearch && marke && sessions && header && windowWidth <= 640) {
            const scheduleSearchHeight = scheduleSearch.clientHeight;
            window.onscroll = function () {
                //to get the position of the schedule search div on the window
                const markRect = marke.getBoundingClientRect();
                const markeRectTop = markRect.top;

                //to get the position of the footer on the window
                const footerRect = sessions.getBoundingClientRect();
                const footerRectTop = footerRect.bottom;

                if (markeRectTop <= header.clientHeight) {
                    scheduleSearch.classList.remove('relative');
                    scheduleSearch.classList.add('fixed-search');
                    if (filterDivMobile) {
                        filterDivMobile.classList.add('pt-24');
                    }
                } else {
                    scheduleSearch.classList.add('relative');
                    scheduleSearch.classList.remove('fixed-search');
                    if (filterDivMobile) {
                        filterDivMobile.classList.remove('pt-24');
                    }
                }

                if (footerRectTop <= header.clientHeight + scheduleSearchHeight) {
                    scheduleSearch.classList.remove('block');
                    scheduleSearch.classList.add('hidden');
                } else {
                    scheduleSearch.classList.add('block');
                    scheduleSearch.classList.remove('hidden');
                }
            };
        }
    }
};
