import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import faq from './ts/faq';
import accordion from './ts/accordion';
import accordionDownloadPreview from './ts/accordion-download-preview';
import accordionFilterSection from './ts/accordion-filter-section';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import contactForm from './ts/contact-form';
import cookieBanner from './ts/cookie-banner';
import scheduleAccordion from './ts/schedule-accordion';
import filtersMobile from './ts/schedule-filters-mobile';
import htmx from 'htmx.org';
import scheduleSearch from './ts/schedule-search';
import backButton from './ts/back-button';
import logoSlider from './ts/logo-slider';
import heroAnimation from './ts/hero-animation';
import ticketshop from './ts/ticketshop';
import countdown from './ts/countdown';
import innernavStacker from './ts/innernav-stacker';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

import './app.scss';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    faq.init();

    accordion.init();

    accordionDownloadPreview.init();

    accordionFilterSection.init();

    navigation.init();

    heroAnimation.init();

    ticketshop.init();

    AOS.init();

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
        });
    }

    htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something
        scheduleAccordion.init();
        const scheduleFiltersMobile = document.querySelectorAll('.schedule-filters-mobile') as NodeListOf<HTMLElement>;
        if (scheduleFiltersMobile.length) {
            scheduleFiltersMobile.forEach(function (filters) {
                filtersMobile.init(filters);
            });
        }

        scheduleSearch.init();
    });

    scheduleSearch.init();

    const scheduleFiltersMobile = document.querySelectorAll('.schedule-filters-mobile') as NodeListOf<HTMLElement>;
    if (scheduleFiltersMobile.length) {
        scheduleFiltersMobile.forEach(function (filters) {
            filtersMobile.init(filters);
        });
    }

    const countdownSections = document.querySelectorAll('.countdown-section') as NodeListOf<HTMLElement>;
    if (countdownSections.length) {
        countdownSections.forEach((section) => {
            countdown.init(section);
        });
    }

    scheduleAccordion.init();

    contactForm.init();

    // remove comment if you want to add a cookie banner
    cookieBanner.init();

    backButton.init();

    logoSlider.init();

    innernavStacker.init();
})();
