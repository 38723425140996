import anime from 'animejs';

export default {
    init() {
        const sessions = document.querySelectorAll('.sessions') as NodeListOf<Element>;
        const hash = location.hash;
        const md = 768;

        if (!sessions) return;

        const moduleButtons = document.querySelectorAll('.module-select') as NodeListOf<HTMLElement>;
        const categoryButtons = document.querySelectorAll('.category-button') as NodeListOf<HTMLElement>;

        // Remove hash in url if module filter button is clicked
        if (moduleButtons.length) {
            moduleButtons.forEach((button) => {
                button.addEventListener('click', () => {
                    const uri = window.location.toString();
                    if (uri.indexOf('#') > 0) {
                        const clean_uri = uri.substring(0, uri.indexOf('#'));
                        window.history.replaceState({}, document.title, clean_uri);
                    }
                });
            });
        }

        // Remove hash in url if category filter button is clicked
        if (categoryButtons.length) {
            categoryButtons.forEach((button) => {
                button.addEventListener('click', () => {
                    const uri = window.location.toString();
                    if (uri.indexOf('#') > 0) {
                        const clean_uri = uri.substring(0, uri.indexOf('#'));
                        window.history.replaceState({}, document.title, clean_uri);
                    }
                });
            });
        }

        sessions.forEach(function (session: Element) {
            const accordionHeads = session.querySelectorAll('.accordion-head') as NodeListOf<HTMLElement>;
            const accordionBodies = session.querySelectorAll('.accordion-body') as NodeListOf<HTMLElement>;

            // open active accoridon depending on hash in url
            if (hash) {
                // close all accordions
                if (accordionBodies.length) {
                    accordionBodies.forEach((acBody: HTMLElement) => {
                        acBody.classList.remove('active');
                        acBody.setAttribute('style', 'display:none;');
                    });
                }

                if (accordionHeads.length) {
                    accordionHeads.forEach((acHead: HTMLElement) => {
                        acHead.classList.remove('active');
                    });
                }

                const currentActiveAccordionHead = document.querySelector(hash) as HTMLElement;

                if (!currentActiveAccordionHead) return;

                const accordion = currentActiveAccordionHead.parentElement;

                if (!accordion) return;

                const currentActiveAccordionBody = accordion.querySelector('.accordion-body') as HTMLElement;

                // Open current active accordion
                currentActiveAccordionHead.classList.add('active');
                if (currentActiveAccordionBody) {
                    currentActiveAccordionBody.classList.add('active');
                    currentActiveAccordionBody.setAttribute('style', 'display:block;');

                    if (window.matchMedia('screen and (min-width: ' + md + 'px)').matches) {
                        currentActiveAccordionBody.classList.remove('overflow-hidden');
                    }
                }

                let headerOffset: number;
                if (window.matchMedia('screen and (min-width: ' + md + 'px)').matches) {
                    headerOffset = 90;
                } else {
                    headerOffset = 150;
                }

                const elementPosition = currentActiveAccordionHead.offsetTop;
                const offsetPosition = elementPosition - headerOffset;

                if (document.referrer.indexOf(window.location.host) !== -1) {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                } else {
                    window.scrollTo({
                        top: offsetPosition
                    });
                }
            }

            if (accordionHeads.length) {
                accordionHeads.forEach((accordionHead) => {
                    // set all accordion bodies to display none
                    if (accordionBodies.length) {
                        accordionBodies.forEach((acBody) => {
                            acBody.setAttribute('style', 'display:none;');
                        });
                    }

                    accordionHead.addEventListener('click', function (event: Event) {
                        // save scroll pos of element if element is clicked
                        const scrollYPos = accordionHead.offsetTop;

                        const timeline = anime.timeline({
                            duration: 500,
                            easing: 'easeOutQuint'
                        });
                        const timelineOpen = anime.timeline({
                            duration: 500,
                            easing: 'easeOutQuint'
                        });
                        const accordion = accordionHead.parentElement;

                        if (!accordion) return;

                        event.stopPropagation();
                        event.preventDefault();

                        // Set animation class after first click
                        if (accordionBodies.length) {
                            accordionBodies.forEach((acBody) => {
                                if (!acBody.classList.contains('animate-body')) {
                                    acBody.classList.add('animate-body');
                                }
                            });
                        }

                        const accordionBody = accordion.querySelector('.accordion-body') as HTMLElement;

                        // close current accordion if its active
                        if (accordionHead.classList.contains('active')) {
                            accordionHead.classList.remove('active');
                            if (accordionBody) {
                                timeline
                                    .add({
                                        begin: () => {
                                            accordionBody.removeAttribute('style');
                                            accordionBody.classList.add('overflow-hidden');
                                        },
                                        targets: accordionBody,
                                        height: [accordionBody.clientHeight, 0],
                                        duration: accordionBody.clientHeight / 3
                                    })
                                    .add(
                                        {
                                            complete: () => {
                                                accordionBody.classList.remove('active');
                                                accordionBody.setAttribute('style', 'display:none;');
                                            }
                                        },
                                        '+=100'
                                    );
                            }
                        } else {
                            // close all accordions
                            if (accordionBodies.length) {
                                accordionBodies.forEach((acBody: HTMLElement) => {
                                    acBody.classList.remove('active');
                                    acBody.classList.add('overflow-hidden');
                                    acBody.setAttribute('style', 'display:none;');
                                });
                            }

                            if (accordionHeads.length) {
                                accordionHeads.forEach((acHead: HTMLElement) => {
                                    acHead.classList.remove('active');
                                });
                            }

                            // set clicked accordion active
                            accordionHead.classList.add('active');
                            if (accordionBody) {
                                setTimeout(() => {
                                    if (window.matchMedia('screen and (min-width: ' + md + 'px)').matches) {
                                        accordionBody.classList.remove('overflow-hidden');
                                    }
                                }, 200);

                                // if accordion is outside range, scroll to top
                                // else animate accordion open
                                if (window.scrollY + 120 <= scrollYPos) {
                                    let offset: number;
                                    if (window.matchMedia('screen and (min-width: ' + md + 'px)').matches) {
                                        offset = 90;
                                    } else {
                                        offset = 150;
                                    }

                                    setTimeout(() => {
                                        // scroll to new y pos of element, it changed because of content changes
                                        window.scrollTo({
                                            top: accordionHead.offsetTop - offset,
                                            behavior: 'smooth'
                                        });
                                    }, 300);

                                    accordionBody.classList.add('active');
                                    accordionBody.setAttribute('style', 'display:block;');
                                } else {
                                    accordionBody.removeAttribute('style');

                                    timelineOpen
                                        .add({
                                            targets: accordionBody,
                                            height: [0, accordionBody.clientHeight],
                                            duration: accordionBody.clientHeight / 3
                                        })
                                        .add(
                                            {
                                                complete: () => {
                                                    accordionBody.classList.add('active');
                                                    accordionBody.setAttribute('style', 'display:block;');
                                                }
                                            },
                                            '+=100'
                                        );
                                }
                                history.pushState(null, ' ', '#' + accordionHead.id);
                            }
                        }
                    });
                });

                // Open first accordion
                const firstActiveAccordionBody = session.querySelector('.accordion-body.active') as HTMLElement;

                if (firstActiveAccordionBody) {
                    firstActiveAccordionBody.setAttribute('style', 'display:block;');

                    if (window.matchMedia('screen and (min-width: ' + md + 'px)').matches) {
                        firstActiveAccordionBody.classList.remove('overflow-hidden');
                    }
                }
            }
        });
    }
};
