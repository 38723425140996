import * as Klaro from 'klaro';

export type Service = {
    cookies: Array<Array<string>>;
    default: boolean;
    name: string;
    purposes: Array<string>;
    required: boolean;
    title: string;
};

export type Event = {
    consent: boolean;
    service: Service;
};

export const KLARO_CHANNEL = 'klaro:callback';
const bc = new BroadcastChannel(KLARO_CHANNEL);

export default {
    init() {
        const config = {
            cookieDomain: '.alpbach.org',
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: false, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: {
                de: {
                    privacyPolicyUrl: 'http://alpbach.org/de/datenschutzerklaerung-verein',
                    consentNotice: {
                        description:
                            'Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}',
                        learnMore: 'Einstellungen'
                    },
                    consentModal: {
                        title: 'Cookie Einstellungen',
                        description: 'Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Datenschutzerklärung (Name)'
                        }
                    },
                    ok: 'Alle akzeptieren',
                    decline: 'Funktionale Cookies',
                    acceptSelected: 'Auswahl speichern',
                    save: 'Speichern',
                    close: 'Schließen',
                    acceptAll: 'Alle akzeptieren',
                    purposes: {
                        functional: 'Funktionale Cookies',
                        analytics: 'Statistiken',
                        marketing: 'Marketing'
                    }
                },
                en: {
                    privacyPolicyUrl: 'http://alpbach.org/privacy-policy-association',
                    consentNotice: {
                        description:
                            'We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}',
                        learnMore: 'Settings'
                    },
                    consentModal: {
                        title: 'Cookie settings',
                        description: 'We use cookies to offer you the best possible functionality. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Privacy Policy (Name)'
                        }
                    },
                    ok: 'Accept all',
                    decline: 'Functional cookies',
                    acceptSelected: 'Save selection',
                    save: 'Save',
                    close: 'Close',
                    acceptAll: 'Accept all',
                    purposes: {
                        functional: 'Functional cookies',
                        analytics: 'Statistic',
                        marketing: 'Marketing'
                    }
                }
            },
            services: [
                {
                    name: 'functional',
                    title: 'Craft CMS',
                    default: true,
                    purposes: ['functional'],
                    cookies: [['CraftSessionId'], ['*_identity'], ['*_username'], ['CRAFT_CSRF_TOKEN']],
                    required: true
                },
                {
                    name: 'linkedin',
                    title: 'LinkedIn Insight',
                    purposes: ['analytics'],
                    cookies: [],
                    required: false
                },
                {
                    name: 'gtm',
                    title: 'Google Tag Manager',
                    purposes: ['analytics'],
                    cookies: [],
                    required: false
                },
                {
                    name: 'google-analytics',
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    cookies: [
                        [/^_ga.*$/i, '/', '.alpbach.org'],
                        [/^_gid.*$/i, '/', '.alpbach.org']
                    ],
                    required: false
                },
                {
                    name: 'facebook',
                    title: 'Facebook Pixel',
                    purposes: ['marketing'],
                    cookies: [[/^_fbp.*$/i, '/', '.alpbach.org']],
                    required: false
                }
            ],
            callback: function (consent: boolean, service: Service) {
                if (service.name === 'linkedin') {
                    const currentScript = document.querySelector('#linkedin-script');

                    if (currentScript !== null && consent === false) {
                        currentScript.remove();
                        return;
                    }

                    if (consent === false) {
                        return;
                    }

                    const script = document.createElement('script');
                    script.setAttribute('id', 'linkedin-script');
                    script.setAttribute('type', 'text/javascript');
                    script.innerText =
                        '(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);';
                    document.body.append(script);

                    bc.postMessage({ consent, service });
                }
            }
        };

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);

        bc.addEventListener('message', (event) => {
            if (event.data.type === 'GET_CONSENT') {
                const serviceName = event.data.payload.service;
                const manager = Klaro.getManager();
                const consents = manager.consents;
                const services = manager.config.services;

                const consent = consents[serviceName] ?? false;
                const service = services.find((s: Service) => s.name === serviceName) ?? null;
                bc.postMessage({ consent, service });
            }
        });
    }
};
