// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

export default {
    init(filters: HTMLElement) {
        if (!filters) return;
        const categoryButton = filters.querySelector('.category-button-mobile');
        const moduleButton = filters.querySelector('.module-button-mobile');
        const categoryOverlay = filters.querySelector('.overlay-categories') as HTMLElement;
        const moduleOverlay = filters.querySelector('.overlay-modules') as HTMLElement;

        // Category/Task Button and Overlay
        if (categoryButton && categoryOverlay) {
            categoryButton.addEventListener('click', function () {
                lock(categoryOverlay);
                categoryOverlay.classList.remove('hidden');
                categoryOverlay.classList.remove('pointer-events-none');
            });

            categoryOverlay.addEventListener('click', function () {
                categoryOverlay.classList.add('hidden');
                categoryOverlay.classList.add('pointer-events-none');
                clearBodyLocks();
            });
        }

        // Module Button and Overlay
        if (moduleButton && moduleOverlay) {
            moduleButton.addEventListener('click', function () {
                lock(moduleOverlay);
                // Remove hash if button is clicked
                removeHash();
                moduleOverlay.classList.remove('pointer-events-none');
                moduleOverlay.classList.remove('hidden');
            });

            moduleOverlay.addEventListener('click', function () {
                moduleOverlay.classList.add('pointer-events-none');
                moduleOverlay.classList.add('hidden');
                // Remove hash if button is clicked
                removeHash();
                clearBodyLocks();
            });
        }

        function removeHash() {
            const uri = window.location.toString();
            if (uri.indexOf('#') > 0) {
                const clean_uri = uri.substring(0, uri.indexOf('#'));
                window.history.replaceState({}, document.title, clean_uri);
            }
        }
    }
};
