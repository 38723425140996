export default {
    init(section: HTMLElement) {
        const container = section.querySelector('.date-container') as HTMLElement;

        if (!container) return;

        const dueDate = container.dataset.date;
        const daysDiv = container.querySelector('.days .number') as HTMLElement;
        const hoursDiv = container.querySelector('.hours .number') as HTMLElement;
        const minutesDiv = container.querySelector('.minutes .number') as HTMLElement;
        const secondsDiv = container.querySelector('.seconds .number') as HTMLElement;

        if (!dueDate || !daysDiv || !hoursDiv || !minutesDiv || !secondsDiv) return;

        const dueDateTime = new Date(dueDate).getTime();

        if (!dueDateTime) return;

        udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);

        // call function each second
        setInterval(function () {
            udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);
        }, 1000);

        function udpateCountdown(
            daysDiv: HTMLElement,
            hoursDiv: HTMLElement,
            minutesDiv: HTMLElement,
            secondsDiv: HTMLElement
        ) {
            const now = new Date().getTime();
            const timeleft = dueDateTime - now;
            const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            daysDiv.innerHTML = days + '';
            hoursDiv.innerHTML = hours + '';
            minutesDiv.innerHTML = minutes < 10 ? '0' + minutes : minutes + '';
            secondsDiv.innerHTML = seconds < 10 ? '0' + seconds : seconds + '';
        }
    }
};
