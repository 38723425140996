import KeenSlider from 'keen-slider';

export default {
    init() {
        const cardSliders = document.querySelectorAll('.logo-slider-section');

        if (!cardSliders.length) {
            return;
        }

        cardSliders.forEach((cardSlider) => {
            const activedSlider = cardSlider.querySelector('.actived-slider') as HTMLElement;
            if (!activedSlider) {
                return;
            }

            const animation = { duration: 22000, easing: (t: number) => t };

            new KeenSlider(
                activedSlider,
                {
                    loop: true,
                    renderMode: 'performance',
                    drag: false,
                    rubberband: false,
                    slides: { perView: 'auto', spacing: 24 },

                    created(s) {
                        s.moveToIdx(5, true, animation);
                    },
                    updated(s) {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    },
                    animationEnded(s) {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    }
                },
                [
                    (slider) => {
                        slider.on('created', () => {
                            slider.container.addEventListener('mouseover', () => {
                                slider.animator.stop();
                            });

                            slider.container.addEventListener('mouseout', () => {
                                slider.moveToIdx(slider.track.details.abs + 5, true, animation);
                            });
                        });
                    }
                ]
            );
        });
    }
};
