export default {
    init() {
        const questionFaq = document.getElementsByClassName('question-faq');

        if (questionFaq) {
            const questionFaqClick = (eventHappened: Event) => {
                const targetClicked = eventHappened.currentTarget as HTMLElement;

                if (!targetClicked) return;

                // element open
                const answerBlock = targetClicked.nextElementSibling as HTMLElement;
                if (!answerBlock) {
                    return;
                }

                // rotate Icon
                const icon = targetClicked.querySelector('.add-icon');
                if (icon) {
                    icon.classList.toggle('rotated');
                }

                targetClicked.classList.add('active');

                if (answerBlock.style.maxHeight) {
                    answerBlock.style.maxHeight = 'none';
                } else {
                    const allAnswerBlocks = document.getElementsByClassName('answer-faq');
                    const allQuestions = document.getElementsByClassName('question-faq');

                    if (!allAnswerBlocks || !allQuestions) {
                        return;
                    }

                    for (let i = 0; i < allQuestions.length; i++) {
                        const icon = allQuestions[i].querySelector('.add-icon');
                        if (icon) {
                            icon.classList.remove('rotated');
                        }
                    }

                    for (let i = 0; i < allAnswerBlocks.length; i++) {
                        const answerBlock = allAnswerBlocks[i] as HTMLElement;
                        if (answerBlock.style.maxHeight) {
                            answerBlock.style.maxHeight = 'none';
                        }
                    }
                    //rotate Icon
                    const icon = targetClicked.querySelector('.add-icon');
                    if (icon) {
                        icon.classList.toggle('rotated');
                    }
                    answerBlock.style.maxHeight = answerBlock.scrollHeight + 'px';
                }
            };

            for (let i = 0; i < questionFaq.length; i++) {
                questionFaq[i].addEventListener('click', function (event) {
                    questionFaqClick(event);
                });
            }
        }
    }
};
