export default {
    init() {
        const formContainers = document.querySelectorAll('.form-section') as NodeListOf<HTMLElement>;

        if (!formContainers) {
            return;
        }

        formContainers.forEach(function (formContainer: HTMLElement) {
            const forms = formContainer.querySelectorAll('#form') as NodeListOf<HTMLElement>;

            if (!forms.length) {
                return;
            }

            forms.forEach(function (form: HTMLElement) {
                const $currentForm = form.querySelector('form');
                if (!$currentForm) return;

                const formHandle = $currentForm.querySelector('input[name="handle"]') as HTMLInputElement;
                if (!formHandle) return;

                //Find the CSRF token hidden input, so we can replace it
                const $csrfInput = $currentForm.querySelector('input[name="CRAFT_CSRF_TOKEN"]') as HTMLInputElement;

                if (!formHandle || !$csrfInput) return;

                // Fetch the new token for the form and replace the CSRF input with our new one
                fetch('/actions/formie/forms/refresh-tokens?form=' + formHandle.value)
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        $csrfInput.outerHTML = result.csrf.input;
                    });
            });
        });
    }
};
