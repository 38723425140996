export default {
    init() {
        const accordionFilterSections = document.querySelectorAll('.accordion-filter-sections') as NodeListOf<Element>;

        if (!accordionFilterSections.length) {
            return;
        }

        accordionFilterSections.forEach(function (accordion) {
            // mobile accordion
            const contentDivsMobile = accordion.querySelectorAll('.click-div-accordion-mobile');

            if (contentDivsMobile.length) {
                contentDivsMobile.forEach((contentDiv) => {
                    contentDiv.addEventListener('click', function (event) {
                        const activeContentDiv = accordion.querySelector('.click-div-accordion-mobile.active');
                        const arrowDown = contentDiv.querySelector('.accordion-arrow-mobile');
                        if (!arrowDown) {
                            return;
                        }
                        event.stopPropagation();

                        if (activeContentDiv && activeContentDiv != contentDiv) {
                            activeContentDiv.classList.remove('active');
                            const activeHiddenDivMobile = activeContentDiv.querySelector('.hidden-content-div-mobile');
                            if (activeHiddenDivMobile) {
                                activeHiddenDivMobile.setAttribute('style', 'maxHeight:0 !important;');
                            }
                            const activeArrowDown = activeContentDiv.querySelector('.accordion-arrow-mobile');
                            if (activeArrowDown) {
                                activeArrowDown.classList.add('rotated');
                            }
                        }

                        const textDivMobile = contentDiv.querySelector('.hidden-content-div-mobile') as HTMLElement;
                        if (contentDiv.classList.contains('active')) {
                            contentDiv.classList.remove('active');
                            arrowDown.classList.add('rotated');
                            if (textDivMobile) {
                                textDivMobile.setAttribute('style', 'maxHeight:0 !important;');
                            }
                        } else {
                            contentDiv.classList.add('active');
                            if (textDivMobile) {
                                textDivMobile.setAttribute('style', '');
                            }
                            arrowDown.classList.remove('rotated');
                        }
                    });
                });
            }

            // desktop accordion

            const clickDivs = accordion.querySelectorAll('.click-div-tab') as NodeListOf<HTMLElement>;

            clickDivs.forEach(function (clickDiv) {
                clickDiv.addEventListener('click', function () {
                    // get index of clicked tab
                    const targetIndex = clickDiv.dataset.targetIndex;
                    if (!targetIndex) {
                        return;
                    }
                    // get title related to clicked tab
                    const currentTitle = accordion.querySelector('[data-target-index="' + targetIndex + '"]');
                    const allTabTitles = accordion.querySelectorAll('.click-div-tab');

                    // remove active from all tab titles
                    allTabTitles.forEach(function (tabTitle) {
                        tabTitle.classList.remove('active');
                    });
                    // add active to clicked tab title
                    if (currentTitle) {
                        currentTitle.classList.add('active');
                    }
                    // get tab content related to clicked tab title
                    const currentTabContent = accordion.querySelector('[data-content-index="' + targetIndex + '"]');
                    const allContentBlocks = accordion.querySelectorAll('.accordion-tab-content');
                    allContentBlocks.forEach(function (content) {
                        content.classList.add('hidden');
                    });
                    if (currentTabContent) {
                        currentTabContent.classList.remove('hidden');
                    }
                });
            });

            if (clickDivs.length > 0) {
                clickDivs[0].click();
            }
        });
    }
};
