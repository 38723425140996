import anime from 'animejs';

export default {
    init() {
        const header = document.getElementById('headerElement') as HTMLElement | null;
        const innerNavs = document.querySelectorAll('.nav-innernav') as NodeListOf<HTMLElement>;
        const progressBar = document.querySelector('.progress-bar') as HTMLElement | null;
        const boundingContainer = document.querySelector('.bounding-container') as HTMLElement | null;
        const initialProgress = window.innerWidth / 10;

        let innerNav: HTMLElement = document.createElement('div');
        let isNavUpdate = false;
        let currentTarget = '';
        let scrollingTimeout: number | undefined;
        let lastScrollY = window.scrollY;
        let scrollDirection: 'up' | 'down' = 'down';
        let lastDirection: 'up' | 'down' = scrollDirection;

        /**
         * Evaluates the currently "scrolled-to" element based on visible elements.
         * @param visibleElements Array of visible HTML elements.
         * @returns The most relevant scrolled-to element, or null if none are visible.
         */
        function evaluateScrolledToElement(visibleElements: HTMLElement[]): HTMLElement | null {
            const currentScrollY = window.scrollY;

            // Determine scroll direction
            if (currentScrollY > lastScrollY) {
                scrollDirection = 'down';
            } else if (currentScrollY < lastScrollY) {
                scrollDirection = 'up';
            }
            lastScrollY = currentScrollY;

            // Reverse array if scroll direction changes
            if (scrollDirection !== lastDirection) {
                visibleElements.reverse();
                lastDirection = scrollDirection;
            }

            // Return the last prioritized element
            return visibleElements[visibleElements.length - 1] || null;
        }

        // Initialize progress bar width
        if (progressBar) progressBar.style.width = `${initialProgress}px`;

        if (innerNavs.length > 0) {
            innerNav = innerNavs[0];
            innerNavs.forEach((nav, index) => {
                if (index !== 0) {
                    nav.style.display = 'none';
                }
            });
        }

        const heroBoxSuccessor = document.querySelector('.hero-innernav') as HTMLElement | null;

        let isFixed = false;
        let initialTop: number | null = null;

        function updateInnerNavPosition(isIntersecting: boolean): void {
            if (innerNav && header) {
                const headerRect = header.getBoundingClientRect();
                const headerBottom = headerRect.bottom;

                if (!isIntersecting && !isFixed) {
                    innerNav.classList.toggle('top-0');
                    if (initialTop === null) {
                        initialTop = parseInt(window.getComputedStyle(innerNav).top || '0', 10);
                    }

                    anime({
                        targets: innerNav,
                        top: `${headerBottom}px`,
                        duration: 88,
                        easing: 'easeOutQuad',
                        begin: () => {
                            innerNav.style.position = 'fixed';
                        }
                    });

                    isFixed = true;
                } else if (isIntersecting && isFixed) {
                    innerNav.classList.toggle('top-0');
                    if (initialTop !== null) {
                        anime({
                            targets: innerNav,
                            top: `${initialTop}px`,
                            duration: 30,
                            easing: 'easeInOutQuad',
                            complete: () => {
                                innerNav.style.position = 'absolute';
                                innerNav.style.top = '';
                                initialTop = null;
                            }
                        });

                        isFixed = false;
                    }
                }
            }
        }

        function moveProgressBarToMenuEntry(targetId: string): void {
            if (!boundingContainer || !progressBar) return;

            const activeLink = boundingContainer.querySelector(`a[href="#${targetId}"]`) as HTMLAnchorElement | null;

            if (activeLink) {
                const linkRect = activeLink.getBoundingClientRect();
                const linkMiddle = linkRect.left + linkRect.width / 2;

                progressBar.style.left = '0';
                progressBar.style.width = `${linkMiddle}px`;
            }
        }

        // Debounce function with a more specific type
        function debounce(func: (entries: IntersectionObserverEntry[]) => void, wait: number, immediate: boolean) {
            let timeout: number | undefined;
            return function (this: unknown, ...args: unknown[]) {
                const later = () => {
                    timeout = undefined;
                    if (!immediate) func.apply(this, args as [IntersectionObserverEntry[]]);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(this, args as [IntersectionObserverEntry[]]);
            };
        }

        const scrollToWithCallback = (options: ScrollToOptions, callback: () => void) => {
            window.scrollTo(options);
            setTimeout(callback, 750);
        };

        document.addEventListener('DOMContentLoaded', () => {
            // Scroll event listener
            window.addEventListener('scroll', () => {
                clearTimeout(scrollingTimeout);

                scrollingTimeout = window.setTimeout(() => {
                    if (Math.abs(window.scrollY) <= 1 && progressBar) {
                        progressBar.style.width = `${initialProgress}px`;
                    }
                }, 50);
            });

            // IntersectionObserver for sticky behavior
            if (heroBoxSuccessor) {
                const observer = new IntersectionObserver(
                    debounce(
                        (entries: IntersectionObserverEntry[]) => {
                            const entry = entries[0];
                            updateInnerNavPosition(entry.isIntersecting);
                        },
                        100,
                        false
                    ),
                    { root: null, threshold: 0, rootMargin: '-85px 0px 0px 0px' }
                );

                observer.observe(heroBoxSuccessor);
            }

            // Target anchors for progress bar behavior
            const targetAnchors = Array.from(document.querySelectorAll('[id]')) as HTMLElement[];
            const anchorObserver = new IntersectionObserver(
                (entries) => {
                    if (!isNavUpdate) {
                        const visibleElements = entries
                            .filter((entry) => entry.isIntersecting)
                            .map((entry) => entry.target as HTMLElement);

                        const scrolledToElement = evaluateScrolledToElement(visibleElements);
                        if (scrolledToElement) {
                            moveProgressBarToMenuEntry(scrolledToElement.id);
                        }
                    }
                },
                { root: null, threshold: 0, rootMargin: '-50% 0px -10% 0px' }
            );

            targetAnchors.forEach((target) => {
                anchorObserver.observe(target);
            });

            // Click event listeners for links in bounding container
            if (boundingContainer) {
                boundingContainer.querySelectorAll('a').forEach((link) => {
                    link.addEventListener('click', (event) => {
                        event.preventDefault();
                        const targetId = (event.currentTarget as HTMLAnchorElement).getAttribute('href')?.substring(1);

                        if (targetId) {
                            const targetElement = document.getElementById(targetId);
                            if (targetElement) {
                                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                                isNavUpdate = true;
                                currentTarget = targetId;

                                moveProgressBarToMenuEntry(currentTarget);

                                scrollToWithCallback({ top: targetPosition - 150, behavior: 'smooth' }, () => {
                                    isNavUpdate = false;
                                    currentTarget = '';
                                });
                            }
                        }
                    });
                });
            }
        });
    }
};
