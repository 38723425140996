// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import anime from 'animejs';

export default {
    init() {
        //////////// Navigation
        const menu = document.getElementById('menu');
        const hamburger = document.getElementById('hamburger');
        const header = document.querySelector('.header-menu') as HTMLElement;
        const body = document.querySelector('body');
        const closeOverlay = document.querySelector('.header-overlay');

        if (!menu || !hamburger || !header || !body || !closeOverlay) {
            return;
        }

        if (hamburger) {
            hamburger.addEventListener('click', function () {
                const timeline = anime.timeline({
                    duration: 400,
                    easing: 'easeOutQuint'
                });

                // close menu
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 0,
                        complete: () => {
                            // clearAllBodyScrollLocks();
                            clearBodyLocks();
                        }
                    });
                } else {
                    // open menu
                    // disableBodyScroll(header);
                    lock(header);
                    menu.classList.add('open');
                    hamburger.classList.add('close');
                    header.classList.add('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 1,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        complete: () => {}
                    });
                }
            });
        }

        const mainButtons = document.querySelectorAll('.main-button') as NodeListOf<HTMLElement>;
        if (!mainButtons) {
            return;
        }
        // active first tab
        mainButtons.forEach(function (button: HTMLElement) {
            button.addEventListener('click', function () {
                // desktop menu
                const activeTargetIndex = button.dataset.targetIndex;
                const subbuttonDivs = document.querySelectorAll('.subbuttons') as NodeListOf<HTMLElement>;
                const activeMainButtonStyling = button.querySelector('.menu-link-div');

                subbuttonDivs.forEach(function (subbuttonDiv: HTMLElement) {
                    const subbuttonsIndex = subbuttonDiv.dataset.contentIndex;

                    if (activeTargetIndex === subbuttonsIndex) {
                        // close subbuttons when clicking same button again
                        if (subbuttonDiv.classList.contains('subbuttons-visible')) {
                            subbuttonDiv.classList.remove('subbuttons-visible');
                            subbuttonDiv.classList.add('not-visible');
                            closeOverlay.classList.add('hidden');
                            if (activeMainButtonStyling) {
                                activeMainButtonStyling.classList.remove('md:active');
                            }
                        } else {
                            // open subbuttons
                            subbuttonDiv.classList.remove('not-visible');
                            subbuttonDiv.classList.add('subbuttons-visible');
                            closeOverlay.classList.remove('hidden');
                            if (activeMainButtonStyling) {
                                activeMainButtonStyling.classList.add('md:active');
                            }
                        }
                    }

                    // close other subbuttons
                    if (activeTargetIndex != subbuttonsIndex) {
                        subbuttonDiv.classList.add('not-visible');
                        subbuttonDiv.classList.remove('subbuttons-visible');
                        if (activeMainButtonStyling) {
                            activeMainButtonStyling.classList.remove('md:active');
                        }
                    }
                });

                // mobile accordion

                function closeSubmenu(mainButton: HTMLElement) {
                    const subbuttonMobile = mainButton.querySelector('.subbuttons-mobile') as HTMLElement | null;
                    const menuLink = mainButton.querySelector('.mobile-icon-div') as HTMLElement;
                    if (!subbuttonMobile || !menuLink) return;

                    subbuttonMobile.setAttribute('style', 'maxHeight:0;');
                    subbuttonMobile.classList.remove('subbuttons-mobile-visible');
                    subbuttonMobile.classList.add('subbuttons-mobile-hidden');
                    menuLink.classList.remove('rotated');
                }

                function openSubmenu(mainButton: HTMLElement) {
                    const subbuttonMobile = mainButton.querySelector('.subbuttons-mobile') as HTMLElement | null;
                    const menuLink = mainButton.querySelector('.mobile-icon-div') as HTMLElement;
                    if (!subbuttonMobile || !menuLink) return;
                    subbuttonMobile.classList.remove('subbuttons-mobile-hidden');
                    subbuttonMobile.classList.add('subbuttons-mobile-visible');
                    subbuttonMobile.style.maxHeight = subbuttonMobile.scrollHeight + 20 + 'px';
                    menuLink.classList.add('rotated');
                }

                function isButtonOpen(mainButton: HTMLElement) {
                    const subbuttonMobile = mainButton.querySelector('.subbuttons-mobile') as HTMLElement | null;
                    if (!subbuttonMobile) return false;
                    return subbuttonMobile.classList.contains('subbuttons-mobile-visible');
                }

                if (isButtonOpen(button)) {
                    closeSubmenu(button);
                } else {
                    mainButtons.forEach((mainButton) => {
                        closeSubmenu(mainButton);
                    });
                    openSubmenu(button);
                }
            });
        });

        // close menu when clicking outside of div
        closeOverlay.addEventListener('click', function () {
            const activeMenuItem = document.querySelector('.subbuttons-visible');
            if (activeMenuItem) {
                activeMenuItem.classList.remove('subbuttons-visible');
                activeMenuItem.classList.add('not-visible');
            }
            closeOverlay.classList.add('hidden');
        });
    }
};
