export default {
    init() {
        const button = document.getElementById('btn-back');
        if (!button) {
            return;
        }

        // check if history domain is same as current domain - if not, hide button (no back button when coming to page via direct link)
        if (document.referrer.indexOf(window.location.host) !== -1) {
            button.addEventListener('click', () => {
                history.back();
                // let windowLocation = window.location;
                // document.referrer ? (windowLocation = document.referrer as unknown as Location) : history.back();
                // location.reload();
                // window.location.replace(document.referrer);
                // window.location.reload();
            });
        } else {
            button.classList.add('hidden');
        }
    }
};
