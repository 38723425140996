export default {
    init() {
        const accordions = document.querySelectorAll('.accordion') as NodeListOf<HTMLElement>;
        if (!accordions) return;

        accordions.forEach((accordion) => {
            const accordionState = accordion.dataset.accordionState;
            const accordionHeadsImageAccordion = accordion.querySelectorAll(
                '.accordion-head-image-accordion'
            ) as NodeListOf<HTMLElement>;
            const accordionBodiesImageAccordion = accordion.querySelectorAll(
                '.accordion-body-image-accordion'
            ) as NodeListOf<HTMLElement>;
            const accordionBodiesImageAccordionMobile = accordion.querySelectorAll(
                '.accordion-body-image-accordion-mobile'
            ) as NodeListOf<HTMLElement>;
            const plusMinusToggles = accordion.querySelectorAll('.plus-minus-toggle') as NodeListOf<HTMLElement>;

            if (accordionHeadsImageAccordion.length) {
                accordionHeadsImageAccordion.forEach((accordionHead) => {
                    accordionHead.addEventListener('click', function (event: Event) {
                        const accordion = accordionHead.parentElement;

                        if (!accordion) return;

                        const activeAccordionHead = accordion.querySelector('.accordion-head-image-accordion.active');
                        const plusMinusToggle = accordion.querySelector('.plus-minus-toggle');
                        event.stopPropagation();

                        if (activeAccordionHead && activeAccordionHead != accordionHead) {
                            activeAccordionHead.classList.remove('active');
                            if (plusMinusToggle) {
                                plusMinusToggle.classList.add('collapsed');
                            }
                            const activeAccordionBody = accordion.querySelector(
                                '.accordion-body-image-accordion.active'
                            );
                            const activeAccordionBodyMobile = accordion.querySelector(
                                '.accordion-body-image-accordion-mobile.active'
                            );
                            if (activeAccordionBody) {
                                activeAccordionBody.setAttribute('style', 'maxHeight:0;');
                            }
                            if (activeAccordionBodyMobile) {
                                activeAccordionBodyMobile.setAttribute('style', 'maxHeight:0;');
                            }
                        }

                        const accordionBody = accordion.querySelector('.accordion-body-image-accordion') as HTMLElement;
                        const accordionBodyMobile = accordion.querySelector(
                            '.accordion-body-image-accordion-mobile'
                        ) as HTMLElement;

                        if (accordionState === 'open-all') {
                            if (accordionHead.classList.contains('active')) {
                                accordionHead.classList.remove('active');
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.add('collapsed');
                                }
                                if (accordionBody) {
                                    accordionBody.classList.remove('active');
                                    accordionBody.setAttribute('style', 'maxHeight:0;');
                                }
                                if (accordionBodyMobile) {
                                    accordionBodyMobile.classList.remove('active');
                                    accordionBodyMobile.setAttribute('style', 'maxHeight:0;');
                                }
                            } else {
                                accordionHead.classList.add('active');
                                if (accordionBody) {
                                    accordionBody.classList.add('active');
                                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                                }
                                if (accordionBodyMobile) {
                                    accordionBodyMobile.classList.add('active');
                                    accordionBodyMobile.style.maxHeight = accordionBodyMobile.scrollHeight + 'px';
                                }
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.remove('collapsed');
                                }
                            }
                        } else {
                            // close current accordion if its active
                            if (accordionHead.classList.contains('active')) {
                                accordionHead.classList.remove('active');
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.add('collapsed');
                                }
                                if (accordionBody) {
                                    accordionBody.classList.remove('active');
                                    accordionBody.setAttribute('style', 'maxHeight:0;');
                                }
                                if (accordionBodyMobile) {
                                    accordionBodyMobile.classList.remove('active');
                                    accordionBodyMobile.setAttribute('style', 'maxHeight:0;');
                                }
                            } else {
                                // close all accordions
                                if (accordionBodiesImageAccordion.length) {
                                    accordionBodiesImageAccordion.forEach((acBody: HTMLElement) => {
                                        acBody.classList.remove('active');
                                        acBody.setAttribute('style', 'maxHeight:0;');
                                    });
                                }

                                if (accordionBodiesImageAccordionMobile.length) {
                                    accordionBodiesImageAccordionMobile.forEach((acBody: HTMLElement) => {
                                        acBody.classList.remove('active');
                                        acBody.setAttribute('style', 'maxHeight:0;');
                                    });
                                }

                                if (accordionHeadsImageAccordion.length) {
                                    accordionHeadsImageAccordion.forEach((acHead: HTMLElement) => {
                                        acHead.classList.remove('active');
                                    });
                                }

                                if (plusMinusToggles.length) {
                                    plusMinusToggles.forEach((acToggle: HTMLElement) => {
                                        acToggle.classList.add('collapsed');
                                    });
                                }

                                // set clicked accordion active
                                accordionHead.classList.add('active');
                                if (accordionBody) {
                                    accordionBody.classList.add('active');
                                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                                }
                                if (accordionBodyMobile) {
                                    accordionBodyMobile.classList.add('active');
                                    accordionBodyMobile.style.maxHeight = accordionBodyMobile.scrollHeight + 'px';
                                }
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.remove('collapsed');
                                }
                            }
                        }
                    });
                });

                // Default accordion state
                // Open all or
                // open first or
                // close all
                const firstActiveAccordionHead = accordion.querySelector(
                    '.accordion-head-image-accordion.active'
                ) as HTMLElement;
                const firstActiveAccordionBody = accordion.querySelector(
                    '.accordion-body-image-accordion.active'
                ) as HTMLElement;
                const firstActiveAccordionBodyMobile = accordion.querySelector(
                    '.accordion-body-image-accordion-mobile.active'
                ) as HTMLElement;
                const firstActivePlusMinusToggle = accordion.querySelector('.plus-minus-toggle.collapsed');

                if (accordionState === 'open-all') {
                    accordionBodiesImageAccordion.forEach((accordionBody) => {
                        accordionBody.style.maxHeight = accordionBody.scrollHeight + 20 + 'px';
                    });
                    accordionBodiesImageAccordionMobile.forEach((accordionBodyMobile) => {
                        accordionBodyMobile.style.maxHeight = accordionBodyMobile.scrollHeight + 20 + 'px';
                    });
                    plusMinusToggles.forEach((toggle) => {
                        toggle.classList.remove('collapsed');
                    });
                } else {
                    if (firstActiveAccordionHead && firstActivePlusMinusToggle) {
                        firstActiveAccordionBody.style.maxHeight = firstActiveAccordionBody.scrollHeight + 20 + 'px';
                        firstActiveAccordionBodyMobile.style.maxHeight =
                            firstActiveAccordionBodyMobile.scrollHeight + 20 + 'px';
                        firstActivePlusMinusToggle.classList.remove('collapsed');
                    }
                }
            }
        });
    }
};
