import { KLARO_CHANNEL, Event } from './cookie-banner';

export default {
    init() {
        const bc = new BroadcastChannel(KLARO_CHANNEL);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const iframeTicketshop = document.querySelector('.iframe-div iframe') as HTMLIFrameElement;
        if (!iframeTicketshop) return;

        //  eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.addEventListener('message', (message: any) => {
            if (message && message.data && message.data.ticketshopHeight) {
                iframeTicketshop.style.height = message.data.ticketshopHeight + 'px';
            }

            if (message && message.data.type === 'GET_CONSENT') {
                bc.postMessage({ type: 'GET_CONSENT', payload: { service: 'linkedin' } });
            }
        });

        bc.addEventListener('message', (event: MessageEvent<Event>) => {
            if (event && event.data) {
                iframeTicketshop.contentWindow?.postMessage(
                    {
                        type: 'consent',
                        payload: event.data
                    },
                    '*'
                );
            }
        });
    }
};
