export default {
    init() {
        const accordions = document.querySelectorAll('.accordion') as NodeListOf<HTMLElement>;

        if (!accordions.length) return;

        accordions.forEach(function (accordion: HTMLElement) {
            const contentDivs = accordion.querySelectorAll('.content-div');
            const accordionState = accordion.dataset.accordionState;

            if (contentDivs.length) {
                contentDivs.forEach((contentDiv) => {
                    contentDiv.addEventListener('click', function (event: Event) {
                        const activeContentDiv = accordion.querySelector('.content-div.active');
                        const plusMinusToggle = contentDiv.querySelector('.plus-minus-toggle');
                        const textDiv = contentDiv.querySelector('.hidden-text-div') as HTMLElement;
                        const textDivMobile = contentDiv.querySelector('.hidden-text-div-mobile') as HTMLElement;
                        const videoDiv = contentDiv.querySelector('.hidden-video-div') as HTMLElement;
                        event.stopPropagation();

                        if (accordionState !== 'open-all') {
                            if (activeContentDiv && activeContentDiv != contentDiv) {
                                const activeTextDiv = activeContentDiv.querySelector('.hidden-text-div');
                                const activeTextDivMobile = activeContentDiv.querySelector('.hidden-text-div-mobile');
                                const activeVideoDiv = activeContentDiv.querySelector('.hidden-video-div');
                                const activeToggle = activeContentDiv.querySelector('.plus-minus-toggle');
                                activeContentDiv.classList.remove('active');

                                if (activeTextDiv) {
                                    activeTextDiv.setAttribute('style', 'maxHeight:0;');
                                }
                                if (activeTextDivMobile) {
                                    activeTextDivMobile.setAttribute('style', 'maxHeight:0;');
                                }
                                if (activeVideoDiv) {
                                    activeVideoDiv.setAttribute('style', 'maxHeight:0;');
                                }
                                if (activeToggle) {
                                    activeToggle.classList.add('collapsed');
                                }
                            }
                        }

                        if (contentDiv.classList.contains('active')) {
                            contentDiv.classList.remove('active');

                            if (plusMinusToggle) {
                                plusMinusToggle.classList.add('collapsed');
                            }
                            if (textDiv) {
                                textDiv.setAttribute('style', 'maxHeight:0;');
                            }
                            if (textDivMobile) {
                                textDivMobile.setAttribute('style', 'maxHeight:0;');
                            }
                            if (videoDiv) {
                                videoDiv.setAttribute('style', 'maxHeight:0;');
                            }
                        } else {
                            contentDiv.classList.add('active');

                            if (textDiv) {
                                textDiv.style.maxHeight = textDiv.scrollHeight + 'px';
                            }
                            if (textDivMobile) {
                                textDivMobile.style.maxHeight = textDivMobile.scrollHeight + 'px';
                            }
                            if (videoDiv) {
                                videoDiv.style.maxHeight = '360px';
                            }
                            if (plusMinusToggle) {
                                plusMinusToggle.classList.remove('collapsed');
                            }
                        }
                    });
                });
            }

            // Default accordion state
            // Open all or
            // open first or
            // close all
            if (accordionState === 'open-all') {
                const textDivs = accordion.querySelectorAll('.hidden-text-div') as NodeListOf<HTMLElement>;
                const textDivsMobile = accordion.querySelectorAll('.hidden-text-div-mobile') as NodeListOf<HTMLElement>;
                const videoDivs = accordion.querySelectorAll('.hidden-video-div') as NodeListOf<HTMLElement>;
                const plusMinusToggles = accordion.querySelectorAll('.plus-minus-toggle') as NodeListOf<HTMLElement>;

                if (textDivs) {
                    textDivs.forEach((div) => {
                        div.style.maxHeight = div.scrollHeight + 20 + 'px';
                    });
                }

                if (textDivsMobile) {
                    textDivsMobile.forEach((div) => {
                        div.style.maxHeight = div.scrollHeight + 20 + 'px';
                    });
                }

                if (videoDivs) {
                    videoDivs.forEach((div) => {
                        div.style.maxHeight = 360 + 'px';
                    });
                }

                if (plusMinusToggles) {
                    plusMinusToggles.forEach((toggle) => {
                        toggle.classList.remove('collapsed');
                    });
                }
            } else {
                const firstActiveContentDiv = accordion.querySelector('.content-div.active') as HTMLElement;

                if (firstActiveContentDiv) {
                    const firstActiveHiddenDiv = firstActiveContentDiv.querySelector('.hidden-text-div') as HTMLElement;
                    const firstActiveHiddenDivMobile = firstActiveContentDiv.querySelector(
                        '.hidden-text-div-mobile'
                    ) as HTMLElement;
                    const firstActiveHiddenVideoDiv = firstActiveContentDiv.querySelector(
                        '.hidden-video-div'
                    ) as HTMLElement;
                    const firstActivePlusMinusToggle = firstActiveContentDiv.querySelector(
                        '.plus-minus-toggle'
                    ) as HTMLElement;

                    if (firstActiveHiddenDiv) {
                        firstActiveHiddenDiv.style.maxHeight = firstActiveHiddenDiv.scrollHeight + 20 + 'px';
                    }
                    if (firstActiveHiddenDivMobile) {
                        firstActiveHiddenDivMobile.style.maxHeight =
                            firstActiveHiddenDivMobile.scrollHeight + 20 + 'px';
                    }
                    if (firstActiveHiddenVideoDiv) {
                        firstActiveHiddenVideoDiv.style.maxHeight = 360 + 'px';
                    }
                    if (firstActivePlusMinusToggle) {
                        firstActivePlusMinusToggle.classList.remove('collapsed');
                    }
                }
            }
        });
    }
};
