import lottie from 'lottie-web';

export default {
    init() {
        const animationContainer = document.querySelector('.lottie-player') as HTMLElement;

        if (!animationContainer) return;

        const animationUrl = animationContainer.dataset.animationUrl;
        if (!animationUrl) return;

        const lottieAnimation = lottie.loadAnimation({
            container: animationContainer,
            autoplay: true,
            path: animationUrl,
            loop: false,
            rendererSettings: {
                className: 'w-auto'
            }
        });

        if (!lottieAnimation) {
            return;
        }
    }
};
